* {
    margin: 0;
    box-sizing: border-box;
    padding: 0;
}

.start__soluciones--process {
    background-color: #e5e5e5;
    text-align: center;
    padding-bottom: 60px;
}

.soluciones__process--logoicon {
    margin-top: 70px;
    text-align: center;
    height: 90px;
}

.soluciones__process--text {
    color: #5e6f7d;
    font-size: 25px;
    font-weight: 500;
    padding: 15px 0 30px;
}

.soluciones__process--cards {
    height: 800px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    row-gap: 30px;
    justify-content: center;
    align-content: center;
}

.soluciones__cards--1 {
    margin-left: auto;
    margin-right: auto;
    width: 350px;
}

.soluciones__cards--1:hover .soluciones__cards--image1 {
    margin-left: auto;
    margin-right: auto;
    width: 350px;
    transform: scale(1.2);
    transition: 0.5s all ease-in-out;
}

.soluciones__cards--1:hover .soluciones__cards--text {
    margin-left: auto;
    margin-right: auto;
    color: #00ccff;
    margin-left: auto;
    margin-right: auto;
    font-weight: 350;
    width: 90%;
    font-size: 13px;
    align-content: center;
    padding-top: 10px;
    transition: 0.5s all ease-in-out;
}

.soluciones__cards--image {
    display: block;
    width: 350px;
    height: 200px;
    overflow: hidden;
}

.soluciones__cards--image1 {
    display: block;
    width: 350px;
    height: 200px;
    transform: scale(1);
    transition: 0.5s all ease-in-out;
}

.soluciones__cards--2 {
    background-color: #ffffff;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    width: 350px;
    height: 150px;
}

.soluciones__cards--2:hover {
    background-color: #ffffff;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    width: 350px;
    height: 150px;
    overflow: hidden;
}

.soluciones__cards--title {
    color: red;
    font-weight: bolder;
    font-size: 20px;
    padding-top: 20px;
}

.soluciones__cards--text {
    margin-left: auto;
    color: #000;
    margin-right: auto;
    font-weight: 350;
    width: 90%;
    font-size: 13px;
    align-content: center;
    padding-top: 10px;
    transition: 0.5s all ease-in-out;
}

@media only screen and (max-width: 770px) {
    .soluciones__process--logoicon {
        margin-top: 30px;
        text-align: center;
        height: 75px;
    }
    .soluciones__process--text {
        width: 80%;
        color: #5e6f7d;
        font-size: 20px;
        font-weight: 500;
        padding: 15px 0 30px;
        margin-right: auto;
        margin-left: auto;
    }
    .soluciones__process--cards {
        height: auto;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
        row-gap: 30px;
        justify-content: center;
        align-content: center;
        margin-bottom: 20px;
    }
    .soluciones__cards--text {
        width: 90%;
        padding-top: 10px;
        font-weight: 350;
    }
}