* {
    box-sizing: border-box;
}

.start__inicio--start {
    height: 600px;
    background-image: url(../../Media/background_start.jpg)
}

.inicio__start--background {
    margin: 0;
    object-fit: contain;
}

.inicio__start--title {
    color: #00ccff;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    padding-top: 65px;
}

.inicio__start--text {
    color: #ffffff;
    font-size: 22px;
    font-weight: lighter;
    text-align: center;
    letter-spacing: 1px;
}

.inicio__start--cards {
    color: #ffffff;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    text-align: center;
    padding-top: 120px;
}

.start__cards--icon {
    font-size: 50px;
    opacity: 80%;
}

.start__cards--title {
    color: #00ccff;
    font-size: 18px;
    font-weight: bold;
}

.start__cards--text {
    color: #ffffff;
    font-size: 15px;
    padding: 20px 0 30px;
}

.start__cards--button {
    background-color: #70b927;
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    padding: 10px 15px;
    border: none;
    border-radius: 300px;
}

.start__cards--button:hover {
    background-color: #70b927;
    color: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.75);
    font-weight: bold;
    font-size: 15px;
    padding: 10px 15px;
    border: none;
    border-radius: 300px;
}

.start__inicio--aboutus {
    text-align: center;
    justify-items: center;
    align-content: center;
}

@media only screen and (max-width: 1150px) {
    .inicio__start--cards {
        color: #ffffff;
        width: 80%;
        height: 30%;
        margin-left: auto;
        margin-right: auto;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        text-align: center;
        padding-top: 40px;
        padding-bottom: 55px;
    }
    .start__inicio--start {
        height: 100%;
        background-image: url(../../Media/background_start.jpg);
        align-items: center;
    }
    .start__cards--text {
        color: #ffffff;
        font-size: 15px;
        padding: 5px 0 10px;
    }
    .inicio__start--text {
        display: none;
    }
    .inicio__start--title {
        color: #00ccff;
        font-size: 25px;
        font-weight: bold;
        text-align: center;
        padding-top: 45px;
    }
    .start__cards--icon {
        font-size: 30px;
        opacity: 80%;
    }
}