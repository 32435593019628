.start__slider {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: auto;
    height: auto;
}

.start__slider--slideLeft {
    width: 10%;
    margin-right: 5%;
    text-align: center;
    font-size: 40px;
    color: #05378d;
}

.start__slider--slideRight {
    width: 10%;
    margin-left: 5%;
    text-align: center;
    font-size: 40px;
    color: #05378d;
}

.start__slider--buttonChange:hover {
    background-color: #05378d;
    color: #ffffff;
    border-radius: 50px;
}

.start__slide--contentImg {
    position: relative;
    display: flex;
    width: 70%;
    height: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.start__slider--imageNone {
    display: none;
}

.start__slider--image {
    width: 900px;
    height: 500px;
    border-radius: 15px;
}

.start__slider--animation {
    animation-name: popImage;
    animation-duration: 1.5s;
}

.start_slider--menuChooser {
    width: 100%;
    text-align: center;
}

.start__slider--chooser {
    width: 40px;
    height: 5px;
    margin: 5px;
    background-color: #bdd5ff;
    border: 1px solid #bdd5ff;
}

.start__slider--chooserSelected {
    background-color: #05378d;
}

@keyframes popImage {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

@media only screen and (max-width: 770px) {
    .start__slider {
        height: auto;
    }
    .start__slide--contentImg {
        width: 100%;
        height: 100%;
        margin: 2%;
    }
    .start__slider--image {
        width: 100%;
        height: 500px;
        border-radius: 15px;
    }
    .start__slider--slideLeft {
        display: none;
    }
    .start__slider--slideRight {
        display: none;
    }
}

@media only screen and (max-width: 420px) {
    .start__slider {
        height: auto;
    }
    .start__slide--contentImg {
        width: 100%;
        height: 100%;
        margin: 2%;
    }
    .start__slider--image {
        width: 100%;
        height: 250px;
        border-radius: 15px;
    }
    .start__slider--slideLeft {
        display: none;
    }
    .start__slider--slideRight {
        display: none;
    }
}