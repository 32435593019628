.start__contact--form {
    background-color: #16619e;
    width: 50%;
    height: 550px;
    margin: 0 auto 40px;
    text-align: center;
    color: #ffffff;
    border-radius: 40px;
}

.contact__form--title {
    width: 90%;
    padding: 40px 0 20px;
    font-size: 30px;
    text-align: center;
    margin: 0 auto;
}

.contact__form--data {
    margin: 0;
    font-size: 20px;
}

.form__data {
    padding: 10px 0;
}

.form__name--place {
    font-size: 15px;
    height: 30px;
    width: 50%;
    border-radius: 50px;
    border: none;
    text-align: center;
}

.contact__form--button {
    font-size: 20px;
    font-weight: 700;
    background-color: #dd0808;
    color: #ffffff;
    border: 1px solid #dd0808;
    padding: 8px 30px;
    border-radius: 50px;
    margin: 35px;
}

@media only screen and (max-width: 770px) {
    .contact__form--title {
        width: 90%;
        padding: 28px 0 20px;
        font-size: 22px;
        text-align: center;
        margin: 0 auto;
    }
    .start__contact--form {
        background-color: #16619e;
        width: 95%;
        height: 550px;
        margin: 0 auto 40px;
        text-align: center;
        color: #ffffff;
        border-radius: 40px;
    }
    .form__name--place {
        width: 80%;
    }
}