* {
    text-decoration: none;
}

.start__jumbotron {
    display: flex;
    height: 450px;
    flex-wrap: wrap;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 80px;
}

.start__jumbotron--contImg {
    width: 50%;
    height: auto;
}

.start__jumbotron--contText {
    width: 50%;
    align-items: center;
    padding-top: 5%;
}

.start__jumbotron--img {
    width: 80%;
    height: 80%;
}

.start__jumbotron--title {
    width: 100%;
    text-align: left;
    font-size: 26px;
    color: #16619e;
    font-weight: 700;
}

.start__jumbotron--text {
    width: 100%;
    text-align: left;
    font-size: 20px;
    margin-top: 3%;
    font-weight: 300;
}

.start__jumbotron--button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-top: 15%;
    background-color: #dd0808;
    color: #ffffff;
    border: 1px solid #dd0808;
    padding: 8px 30px;
    border-radius: 50px;
    text-decoration: none;
}

.start__jumbotron--button:hover {
    box-shadow: 0 2px 4px rgb(0, 0, 0, .4);
}


/* ************************************************************************ */

.start__jumbotron2--bg {
    box-sizing: border-box;
    background-image: url(../../Media/jumbotron2_bg.jpg);
    background-position: center;
    background-size: cover;
    object-fit: contain;
    height: 610px;
}

.start__jumbotron2 {
    display: flex;
    height: 610px;
    flex-wrap: wrap;
    margin-left: 15%;
    margin-right: 15%;
    align-content: center;
}

.start__jumbotron2--contImg {
    width: 50%;
    height: auto;
}

.start__jumbotron2--contText {
    width: 50%;
    align-content: center;
    text-align: center;
}

.start__jumbotron2--img {
    width: 80%;
    height: 80%;
}

.start__jumbotron2--title {
    width: 100%;
    font-size: 30px;
    color: #00ccff;
    font-weight: 700;
    padding: 30px;
}

.start__jumbotron2--text {
    width: 100%;
    font-size: 25px;
    font-weight: 300;
    color: #ffffff;
}

.start__jumbotron2--icon {
    color: #ffffff;
    font-size: 50px;
}

.start__jumbotron2--icon2 {
    display: block;
    font-size: 20px;
    font-weight: 700;
    background-color: #dd0808;
    color: #ffffff;
    float: right;
    margin-left: 6px;
}

.start__jumbotron2--button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    background-color: #dd0808;
    color: #ffffff;
    margin-top: 40px;
    border: 1px solid #dd0808;
    padding: 8px 30px;
    border-radius: 50px;
    text-decoration: none;
}

.start__jumbotron2--button:hover {
    box-shadow: 0 2px 4px rgb(0, 0, 0, .4);
}


/* **********************************************************************  */

@media only screen and (max-width: 770px) {
    .start__jumbotron {
        height: 450px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        margin-left: 15%;
        margin-right: 15%;
        margin-top: 80px;
        margin-bottom: 80px;
        align-content: center;
        justify-items: center;
        text-align: center;
    }
    .start__jumbotron--contImg {
        width: 250px;
        height: auto;
        align-content: center;
        justify-items: center;
        text-align: center;
    }
    .start__jumbotron--contText {
        width: 100%;
        align-items: center;
        padding-top: 5px;
    }
    .start__jumbotron--img {
        width: 100%;
        height: 80%;
    }
    .start__jumbotron--title {
        width: 100%;
        text-align: center;
        font-size: 18px;
        color: #16619e;
        font-weight: 700;
    }
    .start__jumbotron--text {
        width: 100%;
        text-align: center;
        font-size: 16px;
        margin-top: 3%;
        font-weight: 300;
    }
}


/* ********************************************************* */

@media only screen and (max-width: 770px) {
    .start__jumbotron2--bg {
        box-sizing: border-box;
        background-image: url(../../Media/jumbotron2_bg.jpg);
        background-position: left;
        background-size: cover;
        object-fit: contain;
        height: 610px;
    }
    .start__jumbotron2 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        height: 610px;
        margin-left: 15%;
        margin-right: 15%;
        align-content: center;
        justify-items: center;
        text-align: center;
    }
    .start__jumbotron2--contImg {
        width: 100%;
        height: auto;
    }
    .start__jumbotron2--contText {
        width: 100%;
        align-content: center;
        text-align: center;
    }
    .start__jumbotron2--img {
        width: 80%;
        height: 80%;
    }
    .start__jumbotron2--title {
        width: 100%;
        font-size: 30px;
        color: #00ccff;
        font-weight: 700;
        padding: 30px 0px;
    }
    .start__jumbotron2--text {
        width: 100%;
        font-size: 25px;
        font-weight: 300;
        color: #ffffff;
    }
    .start__jumbotron2--icon {
        color: #ffffff;
        font-size: 50px;
    }
    .start__jumbotron2--icon2 {
        display: block;
        font-size: 20px;
        font-weight: 700;
        background-color: #dd0808;
        color: #ffffff;
        float: right;
        margin-left: 6px;
    }
    .start__jumbotron2--button {
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        background-color: #dd0808;
        color: #ffffff;
        margin-top: 40px;
        border: 1px solid #dd0808;
        padding: 8px 30px;
        border-radius: 50px;
        text-decoration: none;
    }
    .start__jumbotron2--button:hover {
        box-shadow: 0 2px 4px rgb(0, 0, 0, .4);
    }
}