* {
    margin: 0;
    box-sizing: border-box;
    padding: 0;
}

.start__complements--complements {
    background-color: #e5e5e5;
    text-align: center;
    padding-bottom: 60px;
}

.complements__process--logoicon {
    margin-top: 70px;
    text-align: center;
    height: 90px;
}

.complements__process--text {
    color: #5e6f7d;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    font-size: 25px;
    font-weight: 500;
    padding: 10px 0 30px;
}

.complements__process--cards {
    height: 800px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    row-gap: 30px;
    justify-content: center;
    align-content: center;
}

.complements__cards--1 {
    margin-left: auto;
    margin-right: auto;
    width: 350px;
}

.complements__cards--1:hover .complements__cards--image1 {
    margin-left: auto;
    margin-right: auto;
    width: 350px;
    transform: scale(1.2);
    transition: 0.5s all ease-in-out;
}

.complements__cards--1:hover .complements__cards--text {
    margin-left: auto;
    margin-right: auto;
    color: #00ccff;
    margin-left: auto;
    margin-right: auto;
    font-weight: 350;
    font-size: 13px;
    align-content: center;
    padding-top: 10px;
    transition: 0.5s all ease-in-out;
}

.complements__cards--image {
    display: block;
    width: 350px;
    height: 200px;
    overflow: hidden;
}

.complements__cards--image1 {
    display: block;
    width: 350px;
    height: 200px;
    transform: scale(1);
    transition: 0.5s all ease-in-out;
}

.complements__cards--2 {
    background-color: #ffffff;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    width: 350px;
    height: 150px;
}

.complements__cards--2:hover {
    background-color: #ffffff;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    width: 350px;
    height: 150px;
    overflow: hidden;
}

.complements__cards--title {
    color: red;
    font-weight: bolder;
    font-size: 20px;
    padding-top: 20px;
}

.complements__cards--text {
    margin-left: auto;
    margin-right: auto;
    font-weight: 350;
    width: 90%;
    font-size: 13px;
    align-content: center;
    padding-top: 10px;
    transition: 0.5s all ease-in-out;
}


/* ************************************************* */

@media only screen and (max-width: 770px) {
    .complements__process--cards {
        grid-template-columns: repeat(1, 1fr);
        color: #000000;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-items: center;
        text-align: center;
        padding-top: 60px;
    }
    .complements__cards--icon {
        font-size: 30px;
        opacity: 80%;
    }
    .complements__cards--title {
        color: red;
        font-size: 20px;
        font-weight: bold;
    }
    .complements__cards--text {
        font-size: 13px;
        font-weight: 350;
    }
    .complements__process--text {
        color: #5e6f7d;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        font-size: 18px;
        font-weight: 500;
        padding: 10px 0 30px;
    }
}