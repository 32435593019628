* {
    box-sizing: border-box;
}

.start__topbar--contact {
    background: #16619e;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    padding-right: 10%;
}

.start__topbar--text {
    margin-right: 3%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.start__topbar--info {
    background: #16619e;
    color: #fff;
    flex-wrap: wrap;
    justify-content: right;
    padding-right: 0%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.start__topbar--infologo {
    margin-right: 5px;
}

.start__topbar--menu {
    display: flex;
    flex-wrap: wrap;
    height: 120px;
    ;
}

.start__topbar--section1 {
    width: 40%;
    text-align: center;
}

.start__topbar--section2 {
    width: 60%;
    text-align: center;
    font-size: 15px;
}

.start__topbar--logo {
    height: 95px;
    margin-top: 5px;
}

.menu_option {
    margin: 0 15px;
    background-color: #fff;
    color: black;
    border: none;
    margin-top: 50px;
    font-size: 16px;
}

.menu_option:hover {
    margin: 0 15px;
    color: #cf0000;
    border-bottom: 3px solid #cf0000;
    margin-top: 50px;
    padding-bottom: 8px;
    font-size: 16px;
}

.contact {
    background-color: #16619e;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    padding: 10px 25px;
    margin: 0 0 0 15px;
    border: none;
    border-radius: 300px;
}

.contact:hover {
    background-color: #fff;
    color: #16619e;
    font-weight: bold;
    font-size: 16px;
    padding: 10px 23px;
    margin: 0 0 0 15px;
    border: 2px solid #16619e;
    border-radius: 300px;
}

.start__topbar--list {
    display: none;
}

@media only screen and (max-width: 1150px) {
    .start__topbar--list {
        display: block;
        float: right;
        background-color: #16619e;
        color: #fff;
        font-size: 30px;
        padding: 7px 15px 0px 15px;
        margin: 30px;
        border: none;
        border-radius: 5px;
    }
    .menu_option {
        display: none;
    }
    .contact {
        display: none;
    }
    .start__topbar--logo {
        height: 70px;
        margin-top: 10px;
        margin-left: 15px;
    }
    .start__topbar--contact {
        background: #16619e;
        color: #fff;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        padding: 0;
    }
    .start__topbar--text {
        margin: 10px 3%;
    }
}


/* ************************************************ */


/*Elementos del menu cel*/

.navbar__session--burgerMenu {
    display: none;
}

.mobile__menu {
    display: none;
}

.sidebar__product--buttonPay {
    background-color: var(--main-light-color);
    border: 1px solid var(--main-light-color);
    font-family: var(--cormorant);
    width: 90%;
    font-size: 15px;
    height: 30px;
    color: #ffffff;
    margin-bottom: 5px;
    margin-top: 20px;
    margin-left: 5%;
    transition: 0.2s;
}

.sidebar__product--buttonPay:hover {
    background-color: #ffffff;
    color: var(--main-light-color);
    border-color: var(--main-light-color);
}

.navbar__session--patron {
    background-image: url('https://scontent-qro1-1.xx.fbcdn.net/v/t1.0-9/147501019_343812660201771_8449802842727296869_o.jpg?_nc_cat=101&ccb=2&_nc_sid=730e14&_nc_ohc=ohAZxocN4yoAX8RFRXs&_nc_ht=scontent-qro1-1.xx&oh=7c76c47acc46433ad591fe690e145207&oe=60449219');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 30px;
}

@media only screen and (max-width: 1000px) {
    .icono--facebook {
        display: none;
    }
    .icono--instagram {
        display: none;
    }
}

@media only screen and (max-width: 820px) {
    .navbar {
        text-align: center;
    }
    .navbar__serch {
        display: none;
    }
    .navbar__logo {
        width: 20%;
        text-align: left;
        align-self: center;
        margin-left: 5%;
    }
    .navbar__cart {
        width: 30%;
    }
    .navbar__session {
        width: 30%;
        text-align: right;
    }
    .navbar__session--content {
        display: none;
    }
    .icono--facebook {
        display: none;
    }
    .icono--instagram {
        display: none;
    }
    .navbar__logo--text {
        display: none;
    }
    .navbar__session--button {
        display: none;
    }
    .navbar__session--burgerMenu {
        display: inline;
        color: var(--main-color);
        background-color: #00000000;
        border: 1px solid var(--main-color);
        font-size: 30px;
        margin-left: 30px;
        font-family: var(--dancing);
    }
    /*Mobile show*/
    .mobile__menu {
        background-color: #16619e;
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        z-index: 70;
        width: 100%;
        height: 100%;
        font-family: var(--dancing);
        color: #ffffff;
        animation-name: right-left;
        animation-duration: 0.2s;
    }
    .mobile__menu--logo {
        width: 100%;
        text-align: center;
    }
    .mobile__menu--image {
        width: auto;
        height: 75px;
        margin-top: 40px;
    }
    .mobile__menu--closeContent {
        position: absolute;
        width: 100%;
        height: 30px;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
    .mobile__menu--close {
        margin: 140px 0 0 0;
        background-color: var(--main-light-color);
        font-family: var(--dancing);
        color: #ffffff;
        border: 0px solid var(--main-light-color);
        font-size: 40px;
    }
    .mobile__menu--buttonContent {
        text-align: center;
        width: 100%;
        margin-top: -20%;
    }
    .mobile__menulist {
        width: 100%;
    }
    .mobile__menu--button {
        display: inline;
        background-color: #ffffff;
        color: #000000;
        border: 0px;
        font-size: 16px;
        font-family: var(--dancing);
        font-size: 20px;
        width: 80%;
        height: 30px;
        border-radius: 40px;
        margin-bottom: 5px;
        transition: 0.2s;
    }
    .mobile__menu--button:hover {
        background-color: var(--main-dark-color);
        color: var(--second-dark-color);
    }
    .mobile__menulist--button {
        display: block;
        background-color: var(--main-color);
        color: #ffffff;
        font-size: 16px;
        font-family: var(--dancing);
        width: 80%;
        height: 25px;
        font-size: 20px;
        margin: 20px auto 20px auto;
    }
    .sidebar__cart {
        width: 100%;
        right: 0px;
        top: 0px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        font-family: var(--dancing);
        animation-name: right-left;
        animation-duration: 0.4s;
    }
    .sidebar__cart--title {
        width: 75%;
    }
    .sidebar__cart--close {
        text-align: right;
        width: 20%;
        color: #ac0606;
    }
    .sidebar__cart--image {
        margin-left: 1em;
        width: 90px;
        height: 90px;
    }
    .sidebar__product--button {
        width: 50%;
    }
}