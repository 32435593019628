.us__jumbotron1--bg {
    box-sizing: border-box;
    background-image: url(../../Media/jumbotron2_bg.jpg);
    background-position: center;
    background-size: cover;
    object-fit: contain;
    height: 610px;
}

.us__jumbotron1 {
    display: flex;
    height: 610px;
    flex-wrap: wrap;
    margin-left: 15%;
    margin-right: 15%;
    align-content: center;
}

.us__jumbotron1--contImg {
    width: 50%;
    height: auto;
}

.us__jumbotron1--contText {
    width: 50%;
    align-content: left;
    text-align: left;
}

.us__jumbotron1--img {
    width: 80%;
    height: 80%;
}

.us__jumbotron1--title {
    width: 100%;
    font-size: 35px;
    color: #ffffff;
    font-weight: 700;
    padding: 15px 0;
}

.us__jumbotron1--line {
    width: 100%;
    border-top: 3px solid #00ccff;
    margin-bottom: 30px;
}

.us__jumbotron1--text {
    width: 100%;
    font-size: 20px;
    font-weight: 300;
    color: #ffffff;
}

.us__jumbotron1--icon {
    color: #ffffff;
    font-size: 50px;
}

.us__jumbotron1--icon2 {
    display: block;
    font-size: 20px;
    font-weight: 700;
    background-color: #dd0808;
    color: #ffffff;
    float: right;
    margin-left: 6px;
}

.us__jumbotron1--buttons {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 35px;
}

.us__jumbotron1--button1 {
    display: block;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    background-color: #dd0808;
    color: #ffffff;
    border: 1px solid #dd0808;
    padding: 8px 30px;
    border-radius: 50px;
}

.us__jumbotron1--button1:hover {
    box-shadow: 0 2px 4px rgb(0, 0, 0, .4);
}

.us__jumbotron1--button2 {
    display: block;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    background-color: transparent;
    color: #ffffff;
    margin-left: 15px;
    border: 3px solid #ffffff;
    padding: 5px 24px;
    border-radius: 50px;
}

.us__jumbotron1--button2:hover {
    display: block;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    background-color: #ffffff;
    color: #104877;
    margin-left: 15px;
    padding: 5px 24px;
    border-radius: 50px;
    box-shadow: 0 2px 4px rgb(0, 0, 0, .4);
}


/* *************************************************** */

.us__jumbotron2--bg {
    box-sizing: border-box;
    background-image: url(../../Media/jumbotronnosotros_bg.jpg);
    background-position: center;
    background-size: cover;
    object-fit: contain;
    height: 450px;
}

.us__jumbotron2 {
    display: flex;
    height: 450px;
    flex-wrap: wrap;
    margin-left: 15%;
    margin-right: 15%;
    align-items: center;
    padding: 50px 0;
}

.us__jumbotron2--contImg {
    width: 50%;
    height: auto;
}

.us__jumbotron2--contText {
    display: block;
    width: 50%;
    align-items: center;
    padding-top: 5%;
}

.us__jumbotron2--img {
    width: 80%;
    height: auto;
}

.us__jumbotron2--title {
    width: 100%;
    text-align: left;
    font-size: 28px;
    color: #dd0808;
    font-weight: 700;
}

.us__jumbotron2--text {
    width: 100%;
    text-align: left;
    font-size: 20px;
    margin-top: 3%;
    font-weight: 300;
}

.us__jumbotron2--button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-top: 30px;
    background-color: #dd0808;
    color: #ffffff;
    border: 1px solid #dd0808;
    padding: 8px 30px;
    border-radius: 50px;
}

.us__jumbotron2--button:hover {
    box-shadow: 0 2px 4px rgb(0, 0, 0, .4);
}


/* *************************************************** */

* {
    box-sizing: border-box;
}

.nosotros__grid--bg {
    margin: 0;
    height: 600px;
    width: auto;
    background-image: url(../../Media/jumbotronnosotros3_bg.jpg);
    background-position: center;
    background-size: cover;
    align-items: center;
    justify-items: center;
}

.nosotros__grid--cards {
    color: #ffffff;
    width: 80%;
    height: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    align-items: center;
    justify-items: center;
    text-align: center;
}

.grid__cards--line {
    width: 20%;
    margin: 15px auto 0;
    border-top: 4px solid #dd0808
}

.grid__cards--icon {
    font-size: 80px;
    opacity: 80%;
}

.grid__cards--title {
    color: #00ccff;
    font-size: 30px;
    font-weight: bold;
}

.grid__cards--text {
    color: #ffffff;
    width: 95%;
    font-size: 20px;
    padding: 20px 0 30px;
    font-weight: 200;
}

.grid__cards--button {
    background-color: #70b927;
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    padding: 10px 15px;
    border: none;
    border-radius: 300px;
}

.grid__cards--button:hover {
    background-color: #70b927;
    color: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.75);
    font-weight: bold;
    font-size: 15px;
    padding: 10px 15px;
    border: none;
    border-radius: 300px;
}


/* ************************************************* */

@media only screen and (max-width: 770px) {
    .us__jumbotron1 {
        height: 610px;
        margin-left: 15%;
        margin-right: 15%;
        align-content: center;
    }
    .us__jumbotron1--contText {
        width: 100%;
        align-content: center;
        text-align: center;
        text-shadow: 1px 1px px #000;
    }
    .us__jumbotron1--buttons {
        width: 100%;
        margin: 35px auto 0;
        align-content: center;
        text-align: center;
        justify-content: center;
    }
    .us__jumbotron1--button1 {
        margin-bottom: 10px;
    }
    .us__jumbotron1--button2 {
        margin-left: 0px;
        margin-bottom: 20px;
    }
}


/* ************************************************* */

@media only screen and (max-width: 770px) {
    .us__jumbotron2 {
        height: 450px;
        margin-left: 5%;
        margin-right: 5%;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .us__jumbotron2--contImg {
        display: none;
    }
    .us__jumbotron2--title {
        text-align: center;
    }
    .us__jumbotron2--text {
        text-align: center;
    }
    .us__jumbotron2--contText {
        display: block;
        width: 100%;
        align-items: center;
        padding-top: 0;
    }
}


/* ************************************************* */

@media only screen and (max-width: 770px) {
    .nosotros__grid--cards {
        display: block;
        color: #ffffff;
        width: 80%;
        height: 100%;
        align-items: center;
        justify-items: center;
        text-align: center;
        padding-top: 100px;
    }
    .grid__cards--icon {
        font-size: 30px;
        opacity: 80%;
    }
    .grid__cards--title {
        color: #00ccff;
        font-size: 22px;
        font-weight: bold;
    }
    .grid__cards--text {
        color: #ffffff;
        font-size: 16px;
        padding: 20px 0 30px;
        font-weight: 200;
    }
}

@media only screen and (max-width: 380px) {
    .nosotros__grid--cards {
        padding-top: 40px;
    }
}