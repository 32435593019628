.complements__jumbotron1--bg {
    box-sizing: border-box;
    background-image: url(../../Media/jumbotron_complementos_bg.jpg);
    background-size: cover;
    background-position: center;
    height: 610px;
}

.complements__jumbotron1 {
    display: flex;
    height: 610px;
    flex-wrap: wrap;
    margin-left: 15%;
    margin-right: 15%;
    align-content: center;
}

.complements__jumbotron1--contImg {
    width: 50%;
    height: auto;
}

.complements__jumbotron1--contText {
    width: 50%;
    align-content: left;
    text-align: left;
}

.complements__jumbotron1--img {
    width: 80%;
    height: 80%;
}

.complements__jumbotron1--title {
    width: 100%;
    font-size: 35px;
    color: #ffffff;
    font-weight: 700;
    padding: 15px 0;
}

.complements__jumbotron1--line {
    width: 100%;
    border-top: 3px solid #00ccff;
    margin-bottom: 30px;
}

.complements__jumbotron1--text {
    width: 100%;
    font-size: 24px;
    font-weight: 300;
    color: #ffffff;
}

.complements__jumbotron1--icon {
    color: #ffffff;
    font-size: 50px;
}

.complements__jumbotron1--icon2 {
    display: block;
    font-size: 20px;
    font-weight: 700;
    background-color: #dd0808;
    color: #ffffff;
    float: right;
    margin-left: 6px;
}

.complements__jumbotron1--buttons {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 35px;
}

.complements__jumbotron1--button1 {
    display: block;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    background-color: #dd0808;
    color: #ffffff;
    border: 1px solid #dd0808;
    padding: 8px 30px;
    border-radius: 50px;
}

.complements__jumbotron1--button1:hover {
    box-shadow: 0 2px 4px rgb(0, 0, 0, .4);
}

.complements__jumbotron1--button2 {
    display: block;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    background-color: transparent;
    color: #ffffff;
    margin-left: 15px;
    border: 3px solid #ffffff;
    padding: 2px 24px;
    border-radius: 50px;
}

.complements__jumbotron1--button2:hover {
    display: block;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    background-color: #ffffff;
    color: #104877;
    margin-left: 15px;
    padding: 2px 24px;
    border-radius: 50px;
}


/* ************************************************ */

@media only screen and (max-width: 770px) {
    .complements__jumbotron1 {
        height: 610px;
        margin-left: 15%;
        margin-right: 15%;
        align-content: center;
        text-align: center;
        justify-content: center;
    }
    .complements__jumbotron1--title {
        width: 100%;
        font-size: 35px;
        color: #104877;
        font-weight: 700;
        padding: 15px 0;
    }
    .complements__jumbotron1--contText {
        width: 90%;
        align-content: center;
        text-align: center;
    }
    .complements__jumbotron1--buttons {
        display: block;
        margin: 35px auto 20px;
        align-content: center;
        text-align: center;
    }
    .complements__jumbotron1--button1 {
        display: inline;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        background-color: #dd0808;
        color: #ffffff;
        border: 1px solid #dd0808;
        padding: 8px 30px;
        border-radius: 50px;
    }
    .complements__jumbotron1--text {
        width: 100%;
        font-size: 22px;
        font-weight: 400;
        color: #ffffff;
        text-shadow: 1px 1px 8px black;
    }
}