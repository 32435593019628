.start__footer--box {
    background-color: #16619e;
    color: hsl(0, 0%, 100%);
    height: 200px;
    max-width: 100%;
    justify-content: center;
    text-align: center;
    font-weight: lighter;
}

.start__footer--logo {
    height: 75px;
    align-content: center;
    margin-top: 30px;
    margin-bottom: 15px;
}


/* ********************************************** */

@media only screen and (max-width: 770px) {
    .start__footer--box {
        background-color: #16619e;
        color: hsl(0, 0%, 100%);
        height: 200px;
        max-width: 100%;
        justify-content: center;
        text-align: center;
        font-weight: lighter;
    }
    .start__footer--logo {
        height: 50px;
        align-content: center;
    }
    .start__footer--text {
        padding: 0;
    }
}