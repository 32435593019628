.start__cripto--card {
    background-color: #e5e5e5;
    padding-bottom: 30px;
    padding-top: 20px;
}

.start__cripto--card1 {
    box-sizing: border-box;
    background-image: url(../../Media/cripto1.jpg);
    background-position: center;
    background-size: cover;
    object-fit: contain;
    width: 90%;
    height: 200px;
    margin: 10px auto 30px;
    border-radius: 10px;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.start__cripto--card2 {
    box-sizing: border-box;
    background-image: url(../../Media/cripto2.jpg);
    background-position: center;
    background-size: cover;
    object-fit: contain;
    width: 90%;
    height: 200px;
    margin: 0 auto 30px;
    border-radius: 10px;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.start__cripto--card3 {
    box-sizing: border-box;
    background-image: url(../../Media/cripto3.jpg);
    background-position: center;
    background-size: cover;
    object-fit: contain;
    width: 90%;
    height: 200px;
    margin: 0 auto 30px;
    border-radius: 10px;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.start__cripto--card4 {
    box-sizing: border-box;
    background-image: url(../../Media/cripto4.jpg);
    background-position: center;
    background-size: cover;
    object-fit: contain;
    width: 90%;
    height: 200px;
    margin: 0 auto 0px;
    border-radius: 10px;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    padding-bottom: 30px;
}

.cripto__card--title {
    font-weight: 600;
    font-size: 30px;
    padding-top: 2%;
    padding-left: 3%;
    color: #00ccff;
    text-shadow: 0px 3px 6px black;
}

.cripto__card--text {
    font-weight: 400;
    font-size: 22px;
    padding-left: 3%;
    text-shadow: 0px 3px 4px black;
}